<template>
  <b-form >
    <b-form-group
      label-class="font-weight-bold"
      :label="$t('email')"
      label-for="email"
    >
      <b-form-input
        id="email"
        v-model="email"
        @input="email = email.toLowerCase()"
        class="input-field"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-class="font-weight-bold"
      :label="$t('password')"
      label-for="password"
      class="password__input"
    >
      <b-input-group id="password">
        <b-form-input
          v-model="password"
          :type="passwordInputType"
          class="border-right-0 input-field"
        ></b-form-input>
        <b-input-group-append is-text>
          <b-icon
            :icon="passwordIcon"
            class="clickable"
            @click="changePasswordInputState"
          ></b-icon>
        </b-input-group-append>
        
      </b-input-group>
    </b-form-group>

    <b-row>
      <b-col class="mt-3">
        <p>{{ $t("button.forgotPassword") }} <span> <button class="no-decoration-btn link-btn" @click="passwordRevovery" type="button">{{ $t("button.clickHere") }}</button></span></p>
        <!-- <p>{{ $t("button.dontHaveAccount") }} <span> <button class="no-decoration-btn link-btn" @click="toggleForm" type="button">{{ $t("register") }}</button></span></p> -->
      </b-col>
    </b-row>

    <b-row class="no-gutters mt-5">
      <b-col class="col-12">
        <b-button
          class="mb-1 login-btn"
          variant="none"
          :disabled="!isFormValid"
          @click="login"
          
        >
          {{ $t("button.login") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  props:{
    toggleForm:{
      type:Function,
      required:false,
      default:()=>{}
    }
  },
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      showPassword: false,
    };
  },
  methods: {
    changePasswordInputState() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("clearAll", null, { root: true });

        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });

        await this.$store.dispatch("auth/retrieveClient");

        this.$store.commit("auth/SET_IS_LOGGED_IN", true);
        // this.$store.commit("settings/SET_TOUR_ACTIVE", this.isFirstLogin);

        const redirectRoute = this.$route.query.redirect;
        if (redirectRoute) {
          this.$router.replace(redirectRoute);
        } else {
          this.$router.replace({ name: "Dashboard" });
        }
      } catch {
      } finally {
        this.$store.dispatch("loader/close");
      }
    },
    doNothing(){

    },
    passwordRevovery() {
      this.$router.push({ name: "PasswordRecovery" });
    },
  },
  computed: {
    isFormValid() {
      return this.email.length > 0 && this.password.length > 0;
    },
    isFirstLogin() {
      return this.$store.getters["auth/isFirstLogin"];
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
    passwordIcon() {
      return this.showPassword ? "eye" : "eye-slash";
    },
  },
};
</script>

<style scoped>
.input-append-btn {
  border: 1px solid #ced4da;
}

.password__input >>> .input-group-text {
  background-color: white;
  border-left: unset;
}

.password__input >>> input {
  min-width: 160px;
}
.input-field{
  height: 48px;
}
.login-btn{
  background-color: var(--emotiva-pink);
  color:#fff;
  padding: 15px;
  border-radius: 28px;
  min-width: 109px;
  min-height: 56px;
}

.link-btn {
  font-weight: bold;
 
}

.link-btn:hover {
  text-decoration: underline;
}

</style>
